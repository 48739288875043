import axios, { AxiosError } from 'axios';

import { getGatewayEndpoint } from '../helpers/api/gatewayUtils';
import { getServiceEndpoint } from '../helpers/api/serviceUtils';
import { baseHeaders, safeGet } from '../helpers/utils';
import {
  SearchFiltersResponse,
  SearchRequest,
} from '../services/Filters/FilterTypes';
import {
  Breadcrumbs,
  Property,
  RegisterToBidQuery,
  SavedSearchType,
  SubmitOfferQuery,
  VT_LISTING_PAGE_BASE_URLS,
} from '../types';

import {
  autocomplete,
  getAreaById,
  lookup,
} from './LocationService/locationApi';
import { Paging } from '@dist-property-frontend/types';

export type APIResponse<T = any> = {
  data?: T;
  status?: number;
};

export type AuthHeaders = {
  pragma: string;
  expires: string;
  'cache-control': string;
  Authorization?: string;
};

export const setAuthHeaders = (token: string | null) => {
  const headers: AuthHeaders = {
    pragma: 'no-cache',
    expires: '0',
    'cache-control': 'no-cache, no-store',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

export const handleApiError = (error: AxiosError) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { data, status } = error.response;
    return {
      ...(data as any),
      status,
    };
  }

  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return {
      status: 500,
    };
  }
};

/**
 * Daft API Gateway
 */
export const daftApiGateway = axios.create({
  baseURL: getGatewayEndpoint({ endpointKey: 'DAFT_API_GATEWAY' }),
  headers: { ...baseHeaders },
});

const getLatestProperty = async () => {
  try {
    const response = await daftApiGateway.get<Property>(
      `api/v1/properties/latest`,
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

/**
 * Search Gateway
 */
const getLocationSubAreas = async (id: string) => {
  try {
    const response = await daftApiGateway.get(`old/v1/area/${id}/within`);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

const getLocationTags = async () => {
  try {
    const response = await daftApiGateway.get(
      'old/v1/filters/autocomplete/areas',
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

type SearchResponse = {
  listings: any;
  showcaseListings: any;
  paging: Paging;
  status: any;
  dfpTargetingValues: any;
  breadcrumbs: Breadcrumbs;
  canonicalUrl: string;
  savedSearch: boolean;
  savedSearchID?: number;
};
const search = async (
  token: string | null,
  query = {},
  searchOnly?: boolean,
  SrpImageRequestString?: string,
): Promise<SearchResponse> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  const url = searchOnly
    ? '/api/v2/ads/listings/search'
    : `/api/v2/ads/listings?${SrpImageRequestString}`;

  try {
    const response = await daftApiGateway({
      headers,
      method: 'post',
      url,
      data: query,
    });
    const { data, status } = response;
    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

const getFilters = async (section: string): Promise<SearchFiltersResponse> => {
  try {
    const response = await daftApiGateway.get(
      `/old/v3/filters/search/${section}`,
    );
    return response.data;
  } catch (error) {
    return {
      showByDefault: [],
      hideByDefault: [],
    };
  }
};

const getOffersFilters = async (
  section: string,
): Promise<SearchFiltersResponse> => {
  try {
    const response = await daftApiGateway.get(
      `/old/v3/filters/search/offers-${section}`,
    );
    return response.data;
  } catch (error) {
    return {
      showByDefault: [],
      hideByDefault: [],
    };
  }
};

type aggreagatedSearchResponse = {
  locationCounts: {
    [key: string]: number;
  };
};
// Allows us to only generate a sitemap for SRP's that have at least 1 listing
const getAggregateSearchListingCounts = async (
  sitemapQuery,
): Promise<aggreagatedSearchResponse> => {
  const { url, data } = sitemapQuery;
  try {
    const response = await daftApiGateway({
      method: 'post',
      url,
      data,
    });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

/**
 * Internal Search Service, this is called by the  Daft API Gateway in the backend
 */
const internalSearchService = axios.create({
  baseURL: getServiceEndpoint({
    endpointKey: 'SEARCH_SERVICE',
    isService: true,
  }),
  headers: { ...baseHeaders },
});

export type InternalSearchServiceResponse = {
  listings: InternalSearchServiceListing[];
  clusters: null;
  totalHits: number;
  mapView: boolean;
  clustered: boolean;
};

export type InternalSearchServiceListing = {
  id: number;
  distance: any;
  isNewHome: boolean;
  isParent: boolean;
  newHomeChildUnits: any;
  childUnits: any;
};

const searchAds = async (
  query: SearchRequest,
): Promise<InternalSearchServiceResponse> => {
  const headers = {
    ...baseHeaders,
  };

  try {
    const response = await internalSearchService({
      headers,
      method: 'post',
      url: '/v1/search',
      data: query,
    });
    const { data, status } = response;
    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

/**
 * Agent Insights Gateways
 */
const adPerformance = async (token: string | null): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `/old/v1/adperformance/dashboards?isAdvantage=true`,
    });
    const { data, status } = response;
    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

const vtsearch = async (
  token: string | null,
  query: any = {},
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  const newQuery = { ...query, paging: { ...query.paging, pageSize: '50' } };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'post',
      url: '/old/v1/valuation-tool/properties',
      data: newQuery,
    });

    const { data, status } = response;
    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

const getTableauDashboardUrls = async (
  token: string | null,
  section: string,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `/old/v1/${section}/dashboards`,
    });
    const { data, status } = response;
    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

const getValuationToolFilters = async (
  section: string,
): Promise<SearchFiltersResponse> => {
  try {
    const response = await daftApiGateway.get(
      `/old/v1/valuation-tool/filters/search/${section}`,
    );
    return response.data;
  } catch (error) {
    return {
      showByDefault: [],
      hideByDefault: [],
    };
  }
};

const dataHubAuth = async (token: string | null): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: '/old/v1/datahub',
    });

    const { data, status } = response;
    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

/**
 * Ad Details Gateway
 */

const getProperty = async (
  listingId: number,
  pageVariant = '',
  token: string | null = null,
  vt_section: string = VT_LISTING_PAGE_BASE_URLS.PROPERTY_PRICE_REGISTER,
  imageSizeRequest?: string,
  baseURL?: string,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    let response: any;
    if (pageVariant === 'VALUATION_TOOL') {
      response = await daftApiGateway({
        headers,
        method: 'get',
        url: `/old/v1/valuation-tool/property/${vt_section}/${listingId}`,
        baseURL: baseURL,
      });
    } else {
      response = await daftApiGateway({
        headers,
        method: 'get',
        url: `/api/v2/ads/listing/${listingId}?${imageSizeRequest}`,
        baseURL: baseURL,
      });
    }

    const { data, status, headers: resHeaders } = response;
    return { ...data, status, headers: resHeaders };
  } catch (error) {
    const redirectUrl = safeGet(error, ['response', 'headers', 'redirect']);
    if (
      redirectUrl &&
      (error.response.status === 404 || error.response.status === 410)
    ) {
      const { data, status, headers } = error.response;

      return { ...data, status, headers };
    }
    return handleApiError(error);
  }
};

const contactFormQuery = async (
  query = {},
  token: string | null = null,
  recaptchaToken: string,
  recaptchaAction: string,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
    'Recaptcha-token': recaptchaToken,
    'Recaptcha-action': recaptchaAction,
    platform: 'web',
  };

  const response = await daftApiGateway({
    headers,
    method: 'post',
    url: '/old/v4/reply',
    data: query,
  });
  const { data, status } = response;
  return { data, status };
};

const submitOffer = async (
  query: SubmitOfferQuery,
  token: string | null = null,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  const { adId } = query;

  const response = await daftApiGateway({
    headers,
    method: 'post',
    url: `/api/v1/properties/${adId}/offers/submissions`,
    data: query,
  });
  const { data, status } = response;
  return { data, status };
};

const getReportAdReasons = async () => {
  try {
    const response = await daftApiGateway.get('/old/v1/report/reasons');
    return response.data;
  } catch (error) {
    // at least return "Other" as default to give the user an option
    return [
      {
        id: 10,
        title: 'Other',
        text: 'Other reason, please specify',
      },
    ];
  }
};

const reportAd = async (query = {}) => {
  try {
    const response = await daftApiGateway({
      method: 'post',
      url: '/old/v1/report',
      headers: {
        pragma: 'no-cache',
        expires: '0',
        'cache-control': 'no-cache, no-store',
      },
      data: query,
    });

    return response;
  } catch (error) {
    if (error.response) {
      if (error.status !== 422) {
        console.warn(error.response);
      }
      const { data = [], status } = error.response;
      return {
        data,
        status,
      };
    }

    if (error.request) {
      console.warn(error.request);
      return {
        status: 500,
      };
    }
  }
};

/**
 * Autocomplete GateWay
 */

const getAllAutocompleteSuggestionsByCategory = async (): Promise<any> => {
  try {
    const response = await daftApiGateway({
      method: 'get',
      url: `/old/v1/location/classifiedAreas`,
    });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

const suggestions = async ({
  text,
  path = '',
}: {
  text: string;
  path: string;
}): Promise<any> => {
  try {
    const response = await daftApiGateway({
      method: 'post',
      url: `/old/v1/autocomplete${path}`,
      data: {
        text,
      },
    });
    const { data } = response;
    return [...data];
  } catch (error) {
    return handleApiError(error);
  }
};

/**
 * Accounts Gateway
 */

type GetSavedAdsParams = {
  token: string | null;
  query: {
    from?: number;
  };
  userId: string;
};

const getSavedAds = async ({
  token,
  query,
  userId,
}: GetSavedAdsParams): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v2/saved-ads/${userId}`,
      params: {
        pageSize: 50,
        from: query.from || 0,
      },
    });

    const { data, status } = response;

    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

type DeleteSavedAdParams = {
  token: string;
  adId: number;
  userId: string;
};

const deleteSavedAd = async ({
  token,
  adId,
  userId,
}: DeleteSavedAdParams): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'delete',
      url: `/api/v1/saved-ads/${userId}/${adId}`,
    });

    const { status } = response;
    return { status };
  } catch (error) {
    return handleApiError(error);
  }
};

type CreateSavedAdParams = {
  token: string | null;
  adId: number;
};

const createSavedAd = async ({
  token,
  adId,
}: CreateSavedAdParams): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'post',
      url: `/api/v1/saved-ads`,
      data: {
        adId,
      },
    });

    const { status } = response;
    return { status };
  } catch (error) {
    return handleApiError(error);
  }
};

const toggleAlerts = async (
  token: string | null,
  adId: number,
  alert: string,
  isActive: boolean,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'patch',
      url: `/api/v1/saved-ads`,
      data: {
        adId,
        [alert]: !isActive,
      },
    });

    const { status } = response;
    return { status };
  } catch (error) {
    return handleApiError(error);
  }
};

type GetSavedSearchesParams = {
  token: string | null;
  userId: number;
};

type GetSavedSearchesResponse = {
  errors?: { message: string }[];
  metadata: {
    [key: string]: string;
  };
  savedSearches: SavedSearchType[];
  status: number;
};

const getSavedSearches = async ({
  token,
  userId,
}: GetSavedSearchesParams): Promise<GetSavedSearchesResponse> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v1/users/${userId}/saved/searches`,
    });

    const { data, status } = response;

    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

type DeleteSavedSearchParams = {
  searchId: number;
  siteUserId: string;
  token: string | null;
};

type DeleteSavedSearchResponse = {
  errors?: { message: string }[];
  status: number;
};

const deleteSavedSearch = async ({
  searchId,
  siteUserId,
  token,
}: DeleteSavedSearchParams): Promise<DeleteSavedSearchResponse> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'delete',
      url: `/api/v1/users/${siteUserId}/saved/searches/${searchId}`,
    });

    const { status } = response;
    return { status };
  } catch (error) {
    return handleApiError(error);
  }
};

type CreateSavedSearchParams = {
  searchRequest: SearchRequest;
  title: string;
  token: string | null;
};

type CreateSavedSearchResponse = {
  errors?: { message: string }[];
  message: string;
  savedSearchID: number;
  status: number;
};

const createSavedSearch = async ({
  searchRequest,
  title,
  token,
}: CreateSavedSearchParams): Promise<CreateSavedSearchResponse> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'post',
      url: '/api/v1/saved-searches',
      data: {
        searchRequest,
        title,
      },
    });

    const { message, savedSearchID, status } = response.data;
    return {
      message,
      savedSearchID,
      status,
    };
  } catch (error) {
    return handleApiError(error);
  }
};

type ToggleSavedSearchAlertsParams = {
  alertType: string;
  searchId: number;
  siteUserId: string;
  token: string | null;
};

type ToggleSavedSearchAlertsResponse = {
  errors?: { message: string }[];
  status: number;
};

const toggleSavedSearchAlerts = async ({
  alertType,
  searchId,
  siteUserId,
  token,
}: ToggleSavedSearchAlertsParams): Promise<ToggleSavedSearchAlertsResponse> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      data: {
        notificationFrequency: alertType,
      },
      headers,
      method: 'patch',
      url: `/api/v1/users/${siteUserId}/saved/searches/${searchId}/alerts`,
    });

    const { status } = response;
    return { status };
  } catch (error) {
    return handleApiError(error);
  }
};

const getBuyingBudgetResults = async (token: string | null): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v3/buying-budget`,
    });

    const { status, data } = response;

    return { status, data };
  } catch (error) {
    return handleApiError(error);
  }
};

const getBuyingBudgetInputs = async (token: string | null): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };
  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v3/buying-budget/user-inputs`,
    });

    const { status, data } = response;
    return { status, data };
  } catch (error) {
    return handleApiError(error);
  }
};

const setReceiveUpdates = async (
  token: string,
  acceptsUpdates: boolean,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'patch',
      url: `/api/v3/buying-budget`,
      data: {
        receiveUpdatesPermission: acceptsUpdates,
      },
    });
    const { status } = response;
    return { status };
  } catch (error) {
    return handleApiError(error);
  }
};

/**
 * Forms
 */
const preApproval = async (
  query: any,
  token: string | null,
  userId: string | undefined,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'post',
      url: `api/v1/users/${userId}/mortgages/pre-approval`,
      data: { ...query, formVersion: 1 },
    });
    const { data, status } = response;
    return { data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

const editPreApproval = async (
  query: any,
  token: string | null,
  userId: string | undefined,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  const response = await daftApiGateway({
    headers,
    method: 'patch',
    url: `api/v1/users/${userId}/mortgages/pre-approval`,
    data: { ...query, formVersion: 1 },
  });
  const { data, status } = response;

  return { data, status };
};

const getPreApprovalInputs = async (
  token: string | null,
  userId: string,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };
  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v1/users/${userId}/mortgages/pre-approval/user-inputs`,
    });

    const { status, data } = response;
    return { status, data };
  } catch (error) {
    return handleApiError(error);
  }
};

const getPreApprovalResults = async (
  token: string | null,
  userId: string,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };
  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `api/v1/users/${userId}/mortgages/pre-approval/summary`,
    });

    const { status, data } = response;

    return { status, data };
  } catch (error) {
    return handleApiError(error);
  }
};

const buyingBudget = async (
  query = {},
  token: string | null,
  userId: string | undefined,
  userEmail: string | undefined,
  methodCall: any = 'post',
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  const response = await daftApiGateway({
    headers,
    method: methodCall,
    url: '/api/v3/buying-budget',
    data: {
      ...query,
      buyingBudgetVersion: 3,
      userId: userId,
      userEmail: userEmail,
    },
  });
  const { data, status } = response;
  return { data, status };
};

const buyingBudgetCallback = async (
  query = {},
  token: string | null,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  const response = await daftApiGateway({
    headers,
    method: 'post',
    url: '/api/v1/mortgage-lead',
    data: query,
  });
  const { data, status } = response;
  return { data, status };
};

type ConfigureBidQueryType = {
  adId: number;
  bookingDeposit: number;
  minimumIncrement: string;
  minimumOfferAmount: string;
};

const enableOffers = async (
  query: ConfigureBidQueryType,
  token: string | null,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  const response = await daftApiGateway({
    headers,
    method: 'post',
    url: `/api/v1/properties/${query.adId}/offers`,
    data: query,
  });

  const { status } = response;
  return { status };
};

const updateOffers = async (
  query: ConfigureBidQueryType,
  token: string | null,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };
  const response = await daftApiGateway({
    headers,
    method: 'patch',
    url: `/api/v1/properties/${query.adId}/offers`,
    data: query,
  });

  const { status } = response;
  return { status };
};

const cancelOffer = async (
  adId: number,
  token: string | null = null,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  const response = await daftApiGateway({
    headers,
    method: 'patch',
    url: `/api/v1/properties/${adId}/offers`,
    data: { status: 'CANCELLED' },
  });
  const { status } = response;
  return { status };
};

const getOffers = async (adID: number, token: string | null) => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v1/properties/${adID}/offers`,
    });
    const { data, status } = response;
    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

const registerToBid = async (
  adID: number,
  query: RegisterToBidQuery,
  token: string | null,
) => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  const response = await daftApiGateway({
    headers,
    method: 'post',
    url: `/api/v1/properties/${adID}/offers/bidders`,
    data: query,
  });
  const { data, status } = response;
  return { ...data, status };
};

const approveBidder = async (adID: number, bidderID: number, token: string) => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'patch',
      url: `/api/v1/properties/${adID}/offers/bidders/${bidderID}`,
      data: { status: 'APPROVED' },
    });
    const { data, status } = response;
    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

const withdrawOffer = async (adID: number, offerID: number, token: string) => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'delete',
      url: `/api/v1/properties/${adID}/offers/submissions/${offerID}`,
    });
    const { data, status } = response;
    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

const acceptOffer = async (adID: number, offerID: number, token: string) => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'patch',
      url: `/api/v1/properties/${adID}/offers/submissions/${offerID}`,
      data: { status: 'WON' },
    });
    const { data, status } = response;
    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

/**
 * Daft Accounts
 */

const propertyPriceEstimate = async (
  query = {},
  token: string | null,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };
  try {
    const response = await daftApiGateway({
      headers,
      method: 'post',
      url: '/api/v1/users/valuations/property/estimate',
      data: query,
    });

    const { data, status } = response;

    const similarProperties = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v1/users/valuations/similar/${response.data.valuation.propertyPriceEstimateId}`,
      data: query,
    });
    const { data: similarPropertiesData } = similarProperties;
    return { data: { ...data, ...similarPropertiesData }, status };
  } catch (error) {
    return handleApiError(error);
  }
};

const requestValuation = async (
  query = {},
  token: string | null,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  const response = await daftApiGateway({
    headers,
    method: 'post',
    url: '/old/v1/users/valuations',
    data: query,
  });
  const { data, status } = response;
  return { data, status };
};

const getInstructionLeads = async (
  token: string | null,
  eircode: string | undefined,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };
  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `api/v1/agents/valuations/areas?eircode=${eircode}`,
    });
    const { status, data } = response;
    return { status, data };
  } catch (error) {
    return handleApiError(error);
  }
};

const getContactFormInputs = async (
  token: string | null,
  userId: string | undefined,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };
  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v1/users/${userId}/saved/replies`,
    });
    const { status, data } = response;
    return { status, data };
  } catch (error) {
    return handleApiError(error);
  }
};

const getAdUserEnquiries = async (
  token: string | null,
  adId: number | undefined,
): Promise<any> => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };
  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v1/ads/${adId}/reply`,
    });
    const { data } = response;
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

const getUserSegments = async (
  token: string | null,
  userId: string | undefined,
): Promise<any> => {
  const headers = { ...setAuthHeaders(token), ...baseHeaders };
  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v1/users/${userId}/segments`,
    });
    const { status, data } = response;
    return { status, data };
  } catch (error) {
    return handleApiError(error);
  }
};

const getAllianzLocationData = async (
  areaId: number | undefined,
): Promise<any> => {
  const headers = { ...baseHeaders };
  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v1/locations/areas/${areaId}/mapping/allianz`,
    });
    const { data } = response;
    return { data };
  } catch (error) {
    return handleApiError(error);
  }
};

/**
 * Daft Metadata Service
 */
const daftMetadataService = axios.create({
  baseURL: getServiceEndpoint({
    endpointKey: 'DAFT_METADATA_SERVICE',
    isService: true,
  }),
  headers: { ...baseHeaders },
});

export type AdDetailsSitemapMetadata = {
  canonicalUrl: string;
  lastModified: string;
};

const getDetailsSitemap = async (
  query: number[],
): Promise<AdDetailsSitemapMetadata[]> => {
  const headers = {
    ...baseHeaders,
  };

  try {
    const response = await daftMetadataService({
      headers,
      method: 'post',
      url: '/v1/metadata/details/sitemap',
      data: query,
    });
    const { data } = response;
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

/*
 * Daft Preview Ad
 */

const getPreviewSRP = async (
  adID: number,
  token: string | null = null,
  previewHash: string,
  baseURL?: string,
) => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      baseURL: baseURL,
      url: `/api/v1/properties/${adID}/preview/search?previewHash=${previewHash}`,
    });
    const { data, status } = response;
    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

const getPreviewPDP = async (
  adID: number,
  token: string | null = null,
  previewHash: string,
  baseURL?: string,
) => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'get',
      baseURL: baseURL,
      url: `/api/v1/properties/${adID}/preview/details?previewHash=${previewHash}`,
    });

    const { data, status } = response;

    return { ...data, status };
  } catch (error) {
    return handleApiError(error);
  }
};

const approveAd = async (
  adId: number,
  token: string | null = null,
  previewHash: string,
  baseURL?: string,
) => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'patch',
      url: `/api/v1/properties/${adId}/preview?previewHash=${previewHash}`,
      baseURL: baseURL,
    });
    const { status } = response;
    return { status };
  } catch (error) {
    return handleApiError(error);
  }
};

const upgradeAd = async (
  adId: number,
  token: string | null = null,
  previewHash: string,
  upgradeType: string,
  baseURL?: string,
) => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'post',
      url: `/api/v1/properties/${adId}/preview/upgrade?previewHash=${previewHash}`,
      data: upgradeType,
      baseURL: baseURL,
    });
    const { status } = response;
    return { status };
  } catch (error) {
    return handleApiError(error);
  }
};

/**
 * Notifier Endpoint
 * @param phoneNumber
 * @param smsCode
 * @param token
 * @param recaptchaToken
 * @param recaptchaAction
 * */

const requestSMSNotifier = async (
  phoneNumber: string,
  smsCode: string | number,
  token: string,
  recaptchaToken: string,
  recaptchaAction: string,
) => {
  const headers = {
    ...setAuthHeaders(token),
    ...baseHeaders,
    'Recaptcha-token': recaptchaToken,
    'Recaptcha-action': recaptchaAction,
  };

  try {
    const response = await daftApiGateway({
      headers,
      method: 'post',
      url: `api/v2/users/notify`,
      data: { phoneNumber, code: smsCode },
    });
    const { status } = response;
    return { status };
  } catch (error) {
    return handleApiError(error);
  }
};

export const PropertyAPI = {
  getProperty,
  getReportAdReasons,
  reportAd,
  getOffers,
  getLatestProperty,
};

export const AgentInsightsAPI = {
  adPerformance,
  vtsearch,
  getTableauDashboardUrls,
  getValuationToolFilters,
  dataHubAuth,
};

export const SearchAPI = {
  search,
  getFilters,
  getOffersFilters,
  getLocationTags,
  getLocationSubAreas,
  getAggregateSearchListingCounts,
};

export const InternalSearchService = {
  searchAds,
};

export const AccountsAPI = {
  deleteSavedAd,
  createSavedAd,
  getSavedAds,
  deleteSavedSearch,
  createSavedSearch,
  toggleAlerts,
  getSavedSearches,
  toggleSavedSearchAlerts,
  getBuyingBudgetResults,
  getBuyingBudgetInputs,
  getPreApprovalInputs,
  getPreApprovalResults,
  setReceiveUpdates,
  approveBidder,
  withdrawOffer,
  acceptOffer,
  cancelOffer,
};

export const AutocompleteAPI = {
  suggestions,
  getAllAutocompleteSuggestionsByCategory,
};

export const Forms = {
  buyingBudget,
  buyingBudgetCallback,
  requestValuation,
  contactFormQuery,
  submitOffer,
  enableOffers,
  updateOffers,
  getContactFormInputs,
  getInstructionLeads,
  registerToBid,
  propertyPriceEstimate,
  preApproval,
  editPreApproval,
  getAdUserEnquiries,
};

export const DaftMetadataService = {
  getDetailsSitemap,
};

export const LocationAPI = {
  autocomplete,
  lookup,
  getAreaById,
};

export const UserSegmentsAPI = {
  getUserSegments,
};

export const AllianzLocationDataAPI = {
  getAllianzLocationData,
};

export const DaftAdPreviewAPI = {
  getPreviewSRP,
  getPreviewPDP,
  approveAd,
  upgradeAd,
};

export const DaftSMSNotifierAPI = {
  requestSMSNotifier,
};
