import React, { Component } from 'react';
import { SearchFilterTypes } from '../../../../services/Filters/FilterTypes';

type Option = {
  value: string;
  displayName: string;
};

type OnChangeCallBack = {
  name: string;
  values: string[];
  searchQueryGroup?: string;
  filterType: SearchFilterTypes.ListSingleSelect;
  currentValue: any;
};

export type SingleSelectFilterProps = {
  name?: string;
  values: Option[];
  id?: string;
  onChange: ({
    name,
    values,
    searchQueryGroup,
    filterType,
  }: OnChangeCallBack) => void;
  className?: string;
  disabled?: boolean;
  currentValue?: { values: string[] };
  searchQueryGroup?: string;
  dataTestId: string;
};

class SingleSelectFilter extends Component<
  SingleSelectFilterProps,
  { value: string }
> {
  getStateForCurrentValue() {
    const { values, currentValue } = this.props;
    const firstValue = values.length ? values[0].value : '';
    return {
      value:
        currentValue && currentValue.values && currentValue.values[0]
          ? currentValue.values[0]
          : firstValue,
    };
  }
  constructor(props: SingleSelectFilterProps) {
    super(props);
    this.state = this.getStateForCurrentValue();
  }

  componentDidUpdate(prevProps: SingleSelectFilterProps) {
    if (this.props.currentValue !== prevProps.currentValue) {
      this.setState(this.getStateForCurrentValue());
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const { onChange, searchQueryGroup, name } = this.props;
    onChange({
      name,
      searchQueryGroup,
      currentValue: { values: [value] },
      values: [value],
      filterType: SearchFilterTypes.ListSingleSelect,
    });
    this.setState({ value });
  };

  render() {
    const {
      name,
      className,
      disabled = false,
      values,
      id,
      dataTestId,
    } = this.props;
    return (
      <select
        name={name}
        className={className}
        disabled={disabled}
        value={this.state.value}
        onChange={this.handleChange}
        id={id ? id : name}
        data-testid={dataTestId ? dataTestId : name}
        aria-label={name}
      >
        {values.map(({ value, displayName }: Option, index) => {
          return (
            <option key={`${value}_${index}`} value={value}>
              {displayName}
            </option>
          );
        })}
      </select>
    );
  }
}

export { SingleSelectFilter };
