import React from 'react';

import styled from 'styled-components';

import { safeGet } from '../../../helpers/utils';

import { SingleSelectFilter } from '../components-filter/SingleSelect/SingleSelectFilter';
import {
  CustomSelect,
  FilterSelectCSS,
  Label,
} from '../Styles/SharedFilterStyles';

const StyledSingleSelectFilter = styled(SingleSelectFilter)`
  ${FilterSelectCSS};
  :disabled {
    background-color: ${({ theme }) => theme.color.GREY_LIGHTEST};
  }
`;

const DefaultSingleSelectFilter = (props: any) => (
  <div>
    {props.showLabel && <Label htmlFor={props.id}>{props.displayName}</Label>}
    <CustomSelect>
      <StyledSingleSelectFilter
        {...props}
        className={
          safeGet(props, ['currentValue', 'values', 0]) !==
            props.values[0].value &&
          safeGet(props, ['currentValue', 'values', 0]) !== undefined
            ? 'filterIsActive'
            : ''
        }
      />
    </CustomSelect>
  </div>
);

export default DefaultSingleSelectFilter;
